import { createApp, defineAsyncComponent, defineProps } from "vue";
import mitt from "mitt";

const Configurator = defineAsyncComponent(() =>
  import("./components/Configurator.vue")
);

const ReferenceBlock = defineAsyncComponent(() =>
  import("./components/ReferenceBlock.vue")
);

const GalleryBlock = defineAsyncComponent(() =>
  import("./components/GalleryBlock.vue")
);


const FloorConfigurator = defineAsyncComponent(() =>
  import("./components/FloorConfigurator.vue")
);

const FloorPage = defineAsyncComponent(() =>
  import("./components/FloorPage.vue")
);

// const el = document.getElementById("app");
const el = document.querySelector("#app");

const emitter = mitt();
const app = createApp({
  components: {
    configurator: Configurator,
    referenceBlock: ReferenceBlock,
    galleryBlock: GalleryBlock,
    floorConfigurator: FloorConfigurator,
    floorPage: FloorPage,
  },
});

app.config.globalProperties.window = window;

app.config.globalProperties.emitter = emitter;

app.mount(el);
